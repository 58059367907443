import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['notify']
  static values = { scrolled: Boolean, scrollMargin: Number }

  connect() {
    this.scrollMarginValue ||= 50
    window.addEventListener('scroll', () => this._dispatch())
    this._dispatch()
  }

  _dispatch() {
    this.scrolledValue = window.scrollY >= this.scrollMarginValue
    const eventName = this.scrolledValue ? 'scrolled' : 'top'
    const event = new Event(eventName, { bubbles: true, cancelable: false })
    for (const el of this.notifyTargets) {
      el.dispatchEvent(event)
    }
  }
}
