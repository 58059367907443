window.dataLayer = window.dataLayer || []

export default gtag

function gtag(args) {
  window.dataLayer.push(args)
}

function sendPage(event = null) {
  let url = window.location.href
  if (event) url = event.detail.url
  gtag({ event: 'pageView', virtualUrl: url })
}

document.addEventListener('turbo:load', sendPage)