import { Controller } from 'stimulus'

import Swiper, {
  Autoplay,
  Controller as SwiperController,
  EffectFade,
} from 'swiper'

Swiper.use([Autoplay, SwiperController, EffectFade])

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
    })
  }
}
