import { Controller } from 'stimulus'


export default class extends Controller {
  static targets = ['dayElement', 'hourElement', 'minuteElement', 'secondElement','dayContainer', 'hourContainer', 'minuteContainer', 'secondContainer', 'countdownBox', 'dayComma', 'hourComma', 'textWrapper', 'textPlaceholder']

  static values = {
    deadline: String
  }

  connect() {
    let countdownDate = new Date(this.deadlineValue).getTime(); /* hrs: min: sec */
    let distance = countdownDate - (new Date().getTime());

    // Update the count down every 1 second
    let x = setInterval( () => {
      if (this.textPlaceholderTarget.style.display !== "none") {
        this.reveal()
      }

      // Get todays date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countdownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));

      // Find the distance in hours between now and the count down date
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element
      if (days == 0 || (hours == 0 && minutes == 0)) {
        this.dayContainerTarget.style.display = 'none';
        this.dayCommaTarget.style.display = 'none';
      } else {
        this.dayElementTarget.innerText = `${days} ${this.pluralizeWord('day', days)}`;
        this.dayCommaTarget.style.display = 'inline';
      }

      if (hours == 0) {
        this.hourContainerTarget.style.display = 'none';
        this.hourCommaTarget.style.display = 'none';
      }
      else {
        this.hourElementTarget.innerText = `${hours} ${this.pluralizeWord('hour', hours)}`;
      }

      if (minutes == 0) {
        this.minuteContainerTarget.style.display = 'none';
      }
      else {
        this.minuteElementTarget.innerText = `${minutes} ${this.pluralizeWord('minute', minutes)}`;
      }

      if (seconds == 0) {
        this.secondContainerTarget.style.display = 'none';
      }
      else {
        this.secondElementTarget.innerText = `${seconds} ${this.pluralizeWord('second', seconds)}`;
      }

      if (days == 0 && hours == 0 && minutes == 0 && seconds >= 0) {
        this.secondContainerTarget.style.display = 'inline-block';
      }

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        this.countdownBoxTarget.innerHTML = "<span class='cdi'>Bidding has Closed</span>";
      }
    }, 1000);

    if (distance < 0) {
      clearInterval(x);
      this.countdownBoxTarget.innerHTML = "<span class='cdi'>Bidding has Closed</span>";
    }
  }

  // Extra method in case they want to use seconds
  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  pluralizeWord(word, count) {
    if (count == 1) {
      return word;
    }
    else {
      return word + 's';
    }
  }

  reveal() {
    this.textPlaceholderTarget.style.display = "none";
    this.textWrapperTarget.style.display = "inline"
  }
}
