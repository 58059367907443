import { Controller } from 'stimulus'

import Swiper, { Autoplay, Controller as Control, EffectFade } from 'swiper'

Swiper.use([Autoplay, Control, EffectFade])

export default class extends Controller {
  static targets = ['imageSliderContainer', 'contentSliderContainer']

  connect() {
    const imageSlider = new Swiper(this.imageSliderContainerTarget, {
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    })

    const contentSlider = new Swiper(this.contentSliderContainerTarget, {
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 8000,
      },
    })

    imageSlider.controller.control = contentSlider
    contentSlider.controller.control = imageSlider
  }
}
