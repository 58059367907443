import { Controller } from 'stimulus'

import Swiper, { Autoplay } from 'swiper'

Swiper.use(Autoplay)

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1.25,
      spaceBetween: 32,
      autoplay: {
        delay: 8000,
      },
    })
  }
}
