import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['disable']

  submit() {
    const form = this.element.closest('form')
    Rails.disableElement(form)
    form.requestSubmit()
  }

  disableChildren(e) {
    if (e.target.checked) return

    this._disableChildren(e.target)
  }

  _disableChildren(element) {
    let children = this._getAllChildren(element)
    children.forEach(this._disable)
  }

  _disable(element) {
    if (!element.checked) return

    element.disabled = true
  }

  _getAllChildren(element) {
    let children = this._getChildren(element)
    let subChildren = this._getSubChildren(children)
    return children.concat(subChildren)
  }

  _getChildren(element) {
    let childrenIds = JSON.parse(element.getAttribute('data-children'))
    // the category ids are unique so the child id can be used
    return this.disableTargets.filter((child) =>
      childrenIds.includes(parseInt(child.getAttribute('data-child-id')))
    )
  }

  _getSubChildren(children) {
    let subChildrenIds = children
      .map((child) => JSON.parse(child.getAttribute('data-children')))
      .flat()
    return this.disableTargets.filter((child) =>
      subChildrenIds.includes(parseInt(child.getAttribute('data-child-id')))
    )
  }
}
