import { Controller } from 'stimulus'

import Swiper, { Autoplay } from 'swiper'

Swiper.use([Autoplay])

export default class extends Controller {
  static targets = ['container']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      autoplay: {
        delay: 6000,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 1.3,
        },
        1280: {
          slidesPerView: 1.7,
        },
      },
    })
  }
}
