import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["select"]
  static values = {
    url: String,
    param: String
  }

  change(event) {
    let currentLevel = parseInt(event.target.getAttribute('data-level'))
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append("target", this.getTarget(currentLevel))
    params.append("hide_target", this.getHideTarget(currentLevel))
    params.append("level", currentLevel + 1)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  getTarget(level) {
    let target = this.selectTargets.find(this.selectLevel(level + 1))

    return target?.id || ''
  }

  getHideTarget(level) {
    let target = this.selectTargets.find(this.selectLevel(level + 2))

    return target?.id || ''
  }

  selectLevel(level) {
    return function (element) {
      return parseInt(element.getAttribute('data-level')) === level;
    }
  }
}
