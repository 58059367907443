import { Controller } from 'stimulus'

import Swiper, { Navigation, Controller as Control } from 'swiper'

import { ImageZoom } from '../scripts/imageZoom'

Swiper.use([Navigation, Control])

export default class extends Controller {
  static targets = ['next', 'prev', 'main', 'thumb']

  connect() {
    const mainSlider = new Swiper(this.mainTarget, {
      slidesPerView: 1,
      spaceBetween: 32,
    })

    const thumbSlider = new Swiper(this.thumbTarget, {
      slidesPerView: 3,
      spaceBetween: 8,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      centeredSlides: true,
    })

    mainSlider.controller.control = [thumbSlider]
    thumbSlider.controller.control = [mainSlider]

    mainSlider.update(true)

    setTimeout(() => {
      this.initZoom('.zoom-image-0')
    }, 200)

    mainSlider.on('slideChange', () =>
      this.initZoom(`.zoom-image-${mainSlider.activeIndex}`)
    )
  }

  initZoom(image) {
    new ImageZoom({
      image,
      result: '#result',
      lens: '#lens',
    }).init()
  }
}
