import { Controller } from 'stimulus'


export default class extends Controller {
  static targets = ["container"]

  connect() {
    setTimeout( () => {
      if (typeof google !== 'undefined') {
        this.initMap()
      }
    },1000)
  }

  initMap() {

    let map
    let bounds = new google.maps.LatLngBounds()
    let markers = []

    let myStyles =[
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          { visibility: "off" }
        ]
      }
    ];

    map = new google.maps.Map(this.containerTarget, {
      center: { lat: 53.3127532287678, lng: -2.3552633323179086 },
      zoom: 12,
      styles: myStyles
    });

    let icon = {
      url: this.iconURL,
      scaledSize: new google.maps.Size(50, 50), // scaled size
      origin: new google.maps.Point(0,0), // origin
      anchor: new google.maps.Point(0, 0) // anchor
    };

      let marker
      let markerPosition = { lat: 53.3127532287678, lng: -2.3552633323179086 }

      let contentString = this.generateContent(location)

      let infowindow = new google.maps.InfoWindow({
        content: contentString,
      })

      marker = new google.maps.Marker({
        position: markerPosition,
        map: map,
        title: "Sandafayre Office",
        icon: icon
      })

      marker.addListener('click', function () {
        infowindow.open(map, marker)
      })
      markers.push(marker)

      bounds.extend(markerPosition)
      marker.setMap(map)
    }

  generateContent(location) {
    return `<div id='content' class='p-2 space-y-2'>
        <h2 class='text-xl font-semibold'>Sandafayre Ltd</h2>
        <a href='https://goo.gl/maps/zqmrB7HHpUxe9jP88' target="_blank" rel="noreferrer" class='inline-block underline hover:no-underline'>Get Directions</a>
      </div>`
  }

  get mapLocations() {
    return JSON.parse(this.containerTarget.dataset.markers)
  }

  get iconURL() {
    return this.containerTarget.dataset.icons
  }

}
