// import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll'
import 'scroll-restoration-polyfill'
import AOS from 'aos'
import 'utils/analytics'

import 'controllers'

// Rails.start()
ActiveStorage.start()
Turbo.start()

if (history.scrollRestoration) {
  history.scrollRestoration = 'auto'
}

document.addEventListener('turbo:load', function () {
  AOS.init()
  const scroller = new SweetScroll()
  if (history.scrollRestoration) {
    history.scrollRestoration = 'auto'
  }
})
