import { Controller } from 'stimulus'

import Swiper, { Autoplay } from 'swiper'

Swiper.use(Autoplay)

export default class extends Controller {
  static values = {
    count: String,
  }

  connect() {
    if (this.countSlides() >= 4) {
      new Swiper(this.element, {
        loop: true,
        loopFillGroupWithBlank: true,
        slidesPerView: 1.25,
        spaceBetween: 32,
        speed: 600,
        autoplay: {
          delay: 4500,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        },
      })
    } else {
      new Swiper(this.element, {
        loop: true,
        loopFillGroupWithBlank: true,
        slidesPerView: 1.25,
        spaceBetween: 32,
        speed: 600,
        autoplay: {
          delay: 4500,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          1024: {
            loop: false,
            centerInsufficientSlides: true,
            slidesPerView: 4,
          },
        },
      })
    }
  }

  countSlides() {
    return this.element.dataset.count
  }
}
