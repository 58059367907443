import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { disabledOnLoad: Boolean }

  connect() {
    if (!this.disabledOnLoadValue) this.focus()
  }

  focus() {
    const element = [...this.element.querySelectorAll('[autofocus]')].find(
      (target) => {
        return this._isVisible(target)
      }
    )
    if (element) element.focus()
  }

  _isVisible(target) {
    return target.offsetWidth > 0 && target.offsetHeight > 0
  }
}
