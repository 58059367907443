export function ImageZoom(options) {
  const { image, result, lens } = options

  this.imageEl = document.querySelector(image)
  this.resultEl = document.querySelector(result)
  this.lensEl = document.querySelector(lens)
  this.posCx = 0
  this.posCy = 0
}

ImageZoom.prototype.setup = function () {
  this.posCx = this.resultEl.offsetWidth / this.lensEl.offsetWidth
  this.posCy = this.resultEl.offsetHeight / this.lensEl.offsetHeight

  this.resultEl.style.backgroundImage = `url(${this.imageEl.src})`
  this.resultEl.style.backgroundSize = `${this.imageEl.offsetWidth * this.posCx}px ${
    this.imageEl.offsetHeight * this.posCy
  }px`
}

ImageZoom.prototype.listen = function () {
  this.lensEl.addEventListener('mousemove', this.move.bind(this))
  this.imageEl.addEventListener('mousemove', this.move.bind(this))
}

ImageZoom.prototype.move = function () {
  let posX = 0
  let posY = 0

  const { cursorPosX, cursorPosY } = this.getCursorPosition()

  event.preventDefault()

  posX = cursorPosX - this.lensEl.offsetWidth / 2
  posY = cursorPosY - this.lensEl.offsetHeight / 2

  if (posX > this.imageEl.width - this.lensEl.offsetWidth) {
    posX = this.imageEl.width - this.lensEl.offsetWidth
  }

  if (posX < 0) {
    posX = 0
  }

  if (posY > this.imageEl.height - this.lensEl.offsetHeight) {
    posY = this.imageEl.height - this.lensEl.offsetHeight
  }

  if (posY < 0) {
    posY = 0
  }

  this.lensEl.style.left = `${posX}px`
  this.lensEl.style.top = `${posY}px`

  this.resultEl.style.backgroundPosition = `-${posX * this.posCx}px -${posY * this.posCy}px`
}

ImageZoom.prototype.getCursorPosition = function () {
  let posX = 0
  let posY = 0

  const imageRect = this.imageEl.getBoundingClientRect()

  posX = event.pageX - imageRect.left
  posY = event.pageY - imageRect.top

  posX = posX - window.pageXOffset
  posY = posY - window.pageYOffset

  return { cursorPosX: posX, cursorPosY: posY }
}

ImageZoom.prototype.init = function () {
  this.setup()
  this.listen()
}
